import React, { Component } from 'react';
import './Timeline.css';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Button } from 'react-bootstrap';

import timeline from './timeline.json';

var moment = require('moment');

class Timeline extends Component {

  constructor() {
    super();

    this.state = {
      displayQuestion: true,
      storyType: "short"
    }

    this.handleQuestionChange = this.handleQuestionChange.bind(this)
    this.handleStoryChange = this.handleStoryChange.bind(this)
    this.createTimeline = this.createTimeline.bind(this)
  }

  handleQuestionChange(event) {
    this.setState({displayQuestion: false})
  }

  handleStoryChange(storyType) {
    this.setState({storyType: storyType})
  }

  createTimeline(ref) {
    let timelineEvents = []
    timeline["Events"].sort(function(a, b) {return new Date(a["date"]) - new Date(b["date"])})

    timeline["Events"].forEach(function(entry) {
      let images = entry["images"].map(function(object, i){
        return <img alt={entry["name"]} src={require("./img/" + object)} style={{maxWidth: "100%", width: "100%"}}/>;
      })

      timelineEvents.push(<VerticalTimelineElement
        key={entry["name"]}
        date={moment(new Date(entry["date"])).format("MMM YYYY")}
        iconStyle={{ backgroundColor: '#2e6da4', color: '#fff' }}
      >
        <h3 className="vertical-timeline-element-title" dangerouslySetInnerHTML={{__html: entry["name"]}}></h3>
        <h5 className="vertical-timeline-element-subtitle" dangerouslySetInnerHTML={{__html: entry["location"]}}></h5>
        <p style={{fontSize: "12px", color: "#2e6da4"}} dangerouslySetInnerHTML={{__html: entry["description"]}}></p>
        <p>{images}</p>
      </VerticalTimelineElement>)
    });
    return timelineEvents
  }

  render() {
    const styleQuestion = this.state.displayQuestion ? {} : {display: 'none'};
    const responseQuestion = this.state.displayQuestion ? {display: 'none'}: {};

    const wholeResponseStyle = this.state.displayQuestion ? {}: {color: "#5cb85c"}
    return (
      <div className="App">
        <h2>
          How Adam proposed to Fiona...
        </h2>
        <div>
        <VerticalTimeline>
          {this.createTimeline()}
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="19th November 2018"
            iconStyle={{ backgroundColor: this.state.displayQuestion ? '#f0ad4e' : '#5cb85c', color: '#fff' }}
            style={wholeResponseStyle}
          >
            <div name="question" style={styleQuestion}>
              <h4 className="vertical-timeline-element-title">Potentially the day Fiona &amp; Adam got engaged</h4>
              <div>
                <h3 style={{marginBottom: "20px", color: "#2e6da4"}}>Fiona Quirke... will you marry me?</h3>
                <Button onClick={this.handleQuestionChange} bsStyle="success" style={{width:"100px", height: "50px"}}>YES!!!!</Button>
                <Button bsStyle="danger" style={{marginLeft:"20px"}} disabled>No</Button>
              </div>
            </div>
            <div name="response" style={responseQuestion}>
              <h1 className="vertical-timeline-element-title" style={{color: "#5cb85c"}}>The day when Fiona &amp; Adam got engaged</h1>
            </div>
          </VerticalTimelineElement>
        </VerticalTimeline>
        </div>
      </div>
    );
  }
}

export default Timeline;
