import React, {Component} from 'react';

class DiscountedRates extends Component {
  render() {
    return <div>
      <div id="accommodation">
        <h3>Accommodation</h3>
        <p>Some rooms have been reserved for us at a discounted rate, once those allocated
        rooms have been reserved, then the normal hotel rates will apply.<br/>
        To get the discounted rate, you'll need to use discount code, so please
        reach out to Fiona or Adam, to get the discount code if needed.</p>
        <h4>How to Book?</h4>
        <ul>
          <li>By calling the hotel directly on: +353 94 9022033 and mention it's for our wedding</li>
          <li>Within 6 months of the wedding, through the Breaffy House Resort <a href="https://www.breaffyhouseresort.com/" target="_blank" rel="noopener noreferrer">website</a></li>
        </ul>
        <h4>Rates</h4>
        <p>The discounted room rates are priced per person, for people over the age of 15.
        Children aged up to 2 are free and between 3-15 are &euro;15 per child and an
        optional &euro;10 per child for dinner on Friday night.<br/>
        Each night stay includes breakfast the following morning and if you are staying Friday
        night then dinner is included on the Friday evening, dinner on Friday is at 7pm.</p>
        <table class="table">
          <thead>
            <tr>
              <th scope="col"># adults per room</th>
              <th scope="col">1 Night (Sat)</th>
              <th scope="col">2 Nights (Fri, Sat)</th>
              <th scope="col">3 Nights (Fri, Sat, Sun)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>&euro;95</td>
              <td>&euro;199</td>
              <td>&euro;274</td>
            </tr>
            <tr>
              <td>2</td>
              <td>&euro;120</td>
              <td>&euro;258</td>
              <td>&euro;338</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
    </div>
  }
}

export default DiscountedRates;
