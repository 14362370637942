import React, {Component} from 'react';

import './WeddingWeekendPlan.css';

class WeddingWeekendPlan extends Component {

  constructor(props) {
    super(props);
    this.state = {showSport: false};

    this.readAnchors = this.readAnchors.bind(this)
  }

  componentDidMount() {
    this.readAnchors(window.location.href)
  }

  readAnchors(url) {
    if (url.indexOf("#sport") > -1) {
      this.setState({showSport: true})
    }
  }

  render() {
    return <div>
      <h1>Wedding Weekend</h1>
      <div className="row">
        <div className="col">
          <h2>Friday 21st February</h2>
          <table className="table">
            <tbody>
            <tr>
            <td></td>
            <td>
              <b>Dinner</b><br/>
              If you need somewhere for dinner before drinks, then there&rsquo;s
              restaurant and bar on-site and Castlebar is about a 10 minute drive away.
            </td>
                </tr>
                  <tr>
                  <td>19:30</td>
                  <td>
                  <b>Welcome Drinks at Breaffy House</b><br/>
                  There will be an area reserved just outside the main bar in the hotel.
                </td>
              </tr>
              {this.state.showSport ? (
                <tr className="sport">
                  <td>19:45</td>
                  <td>Derby v Fulham, Sky Sports</td>
                </tr>
              ) : (<tr></tr>) }
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2>Saturday 22nd February</h2>
          <table className="table">
            <tbody>
              <tr>
                <td>12:00</td>
                <td>
                  <b>Coach Leaves Breaffy House</b><br/>
                  For those staying at Breaffy House on Friday night, we&rsquo;ve arranged
                  for a coach to take you to Knock, and of course, bring you back to Breaffy.
                </td>
              </tr>
              {this.state.showSport ? (
                <tr className="sport">
                  <td>12:30</td>
                  <td>Chelsea v Tottenham, BT Sport</td>
                </tr>
              ) : (<tr></tr>) }
              <tr>
                <td>13:00</td>
                <td>
                  <b>Church ceremony kicks off</b><br/>
                  Plan to be sat down in Knock Parish Church by then.  It&rsquo;s
                  the church on the R329 road, <a href="https://goo.gl/maps/CqKTqtAMStQGp6A27" target="_blank" rel="noopener noreferrer">Google Maps</a>.<br/>
                </td>
              </tr>
              <tr>
                <td>15:00</td>
                <td>
                  <b>Reception Drinks at Breaffy House</b><br/>
                  <a href="https://goo.gl/maps/UzjrqDyHZkw" target="_blank" rel="noopener noreferrer">Google Maps</a>
                </td>
              </tr>
              {this.state.showSport ? (
                <tr className="sport">
                  <td>15:00</td>
                  <td>Charlton v Luton, Valley Pass</td>
                </tr>
              ) : (<tr></tr>) }
              <tr>
                <td>17:30</td>
                <td>
                  <b>Dinner</b><br/>
                  From around 17:00, you&rsquo;ll be asked to make your way into the
                  hall, with dinner aiming to start at half past.
                </td>
              </tr>
              {this.state.showSport ? (
                <tr className="sport">
                  <td>17:30</td>
                  <td>Leicester City v Man City, Sky Sports</td>
                </tr>
              ) : (<tr></tr>) }
              <tr>
                <td>21:30</td>
                <td>
                  <b>Music</b><br/>
                  There will be a band and DJ busting out tunes until 2am.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2>Sunday 23rd February</h2>
          <table className="table">
            <tbody>
              {this.state.showSport ? (
                <tr className="sport">
                  <td>04:00</td>
                  <td>Wilder v Fury 2</td>
                </tr>
                ) : (<tr></tr>) }
              {this.state.showSport ? (
                <tr className="sport">
                  <td>15:00</td>
                  <td>England v Ireland, Six Nations Rugby</td>
                </tr>
              ) : (<tr></tr>) }
              {this.state.showSport ? (
                <tr className="sport">
                  <td>16:30</td>
                  <td>Arsenal v Everton, Sky Sports</td>
                </tr>
              ) : (<tr></tr>) }
              <tr>
                <td>17:00</td>
                <td>
                  <b>Coach Leaves Breaffy House for After Party</b><br/>
                  For those staying at Breaffy House on Sunday night, we&rsquo;ve arranged
                  for a coach to take you and drop you back around midnight.<br/>
                  The after party will be at Fiona&rsquo;s aunts pub in Aghamore,
                  roughly 30 minutes away from Breaffy.
                </td>
              </tr>
              <tr>
                <td>17:30 - Midnight</td>
                <td>
                  <p><b>After Party at Village Inn Pub in Aghamore</b><br/>
                  We&rsquo;ve organised dinner and some Karaoke, at the Village Inn
                  pub in Aghamore. Food will be from 18:00 and music to follow.</p>
                  <p>Village Inn, Aghamore, Co. Mayo, F35 VN34<br/>
                  <a href="https://goo.gl/maps/d3kGPRMj4zXbMdNp7" target="_blank" rel="noopener noreferrer">Google Maps</a></p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  }
}

export default WeddingWeekendPlan;
