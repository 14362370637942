import React, {Component} from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import CountDown from 'reactjs-countdown';

import './AppRouter.css';

import Timeline from './Timeline';
import Wedding from './Wedding';
import WeddingWeekendPlan from './WeddingWeekendPlan';
import DiscountedRates from './DiscountedRates';
import GettingThere from './GettingThere';
import Rsvp from './Rsvp';

class AppRouter extends Component {
  constructor(props) {
    super(props);

    this.state={
      weddingClass: "",
      gettingThereClass: "",
      ourStoryClass: "",
      showBanner: "",
      bannerText: "",
      bannerImage: "banner.jpg",
      showTimer: true
    }

    this.onLinkSelected = this.onLinkSelected.bind(this)
  }

  componentDidMount() {
    this.onLinkSelected(window.location.href)

  }

  onLinkSelected(url) {
    if (url.indexOf("getting-there") > -1) {
      this.setState({
        weddingClass: "",
        gettingThereClass: "selected",
        ourStoryClass: "",
        showBanner: "",
        bannerText: "<h1>Scenes Pending in</h1>",
        bannerImage: "oktoberfest.jpg",
        showTimer: true
      })
    } else if (url.indexOf("our-story")  > -1) {
      this.setState({
        weddingClass: "",
        gettingThereClass: "",
        ourStoryClass: "selected",
        showBanner: "banner-hidden",
        bannerText: "",
        bannerImage: "",
        showTimer: false
      })
    } else if (url.indexOf("discounted-rates")  > -1 || url.indexOf("wedding-weekend")  > -1) {
      this.setState({
        weddingClass: "",
        gettingThereClass: "",
        ourStoryClass: "",
        showBanner: "banner-hidden",
        bannerText: "",
        bannerImage: "",
        showTimer: false
      })
    } else {
      this.setState({
        weddingClass: "selected",
        gettingThereClass: "",
        ourStoryClass: "",
        showBanner: "",
        bannerText: "<h1>Fiona &amp; Adam Are Getting Married</h1><h3>22nd February 2020</h3>",
        bannerImage: "banner.jpg",
        showTimer: false
      })
    }
  }

  render() {
    let banner = <div/>;
    let timer = <div/>

    if (this.state.showTimer) {
      timer = <div><CountDown end="22 Feb 2020 13:00:00 GMT" /></div>
    }

    if (this.state.bannerImage) {
      banner = <div>
        <div className="centered">
          <div dangerouslySetInnerHTML={{__html: this.state.bannerText}}></div>
          {timer}
        </div>
        <img alt={this.state.bannerImage} src={require("./img/" + this.state.bannerImage)} style={{zIndex:-1, maxWidth: "100%", width: "100%", opacity: 0.5}} />
      </div>
    }

    return <Router>
    <div>
    <div className="container border-left border-right full-width shadow">

    <div id="banner" style={{position: 'relative'}} className={this.state.showBanner}>
    {banner}
    </div>
        <hr className="menu" style={{marginTop: "2px"}}/>
        <ul className="nav justify-content-center" style={{paddingBottom: "1rem"}}>
          <li><Link onClick={() => this.onLinkSelected("wedding-link")} className={"nav-link " + this.state.weddingClass} to="/">Wedding</Link></li>
          <li><Link onClick={() => this.onLinkSelected("getting-there-link")} className={"nav-link " + this.state.gettingThereClass} to="/getting-there">Getting There</Link></li>
          <li><Link onClick={() => this.onLinkSelected("our-story-link")} className={"nav-link " + this.state.ourStoryClass} to="/our-story">Our Story</Link></li>
        </ul>
        </div>
        <div className="container border-left border-right shadow" style={{paddingBottom: "20px", marginBottom: "10px"}}>
        <hr style={{marginTop:0}} />

      <Route exact path="/" component={Wedding} />
      <Route path="/rsvp" component={Rsvp} />
      <Route path="/getting-there" component={GettingThere} />
      <Route path="/our-story" component={Timeline} />
      <Route path="/discounted-rates" component={DiscountedRates} />
      <Route path="/wedding-weekend" component={WeddingWeekendPlan} />
    </div>
    </div>
    </Router>
  }
}

export default AppRouter;
