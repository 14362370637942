import React, {Component} from 'react';

class GettingThere extends Component {
  render() {
    return <div>
      <h3>Getting There</h3>
      <h4 style={{textDecoration: "underline"}}>Travelling in Ireland</h4>
      <p>
        If you're flying to Ireland and won't be hiring a car over the weekend of the wedding,
        let us know your flight details and we will try to help arrange transport to and from the airport.
      </p>
      <p>
        On the day of the wedding, we will look to arrange a coach to transport people between the
        hotel and wedding venue in Knock. If you will need transport on the day let us know so,
        we can get rough numbers.
      </p>
      <p>
        Knock Airport is the nearest airport to the reception venue, which is about a 35
        minute drive to Breaffy House Resort.<br/>
        You can fly directly to Knock from Gatwick, Stansted and Luton, <a href="https://www.skyscanner.net" target="_blank" rel="noopener noreferrer">Sky Scanner</a> is
        a convenient place to check for flights.
      </p>
      <p>
        Dublin Airport is roughly a 3 hour drive to Breaffy House Resort and Shannon
        Airport is just under 2 hours.
      </p>
      </div>
  }
}

export default GettingThere;
