import React, {Component} from 'react';
import { Link } from "react-router-dom";

import './Wedding.css';

class Wedding extends Component {
  initMap() {
    let map = new window.google.maps.Map(document.getElementById('map'), {
      center: {lat: 53.7265549, lng: -7.8292493},
      zoom: 7,
      mapTypeControl: false,
      streetViewControl: false
    });

    let venues = [
      {"name": "Breaffy House Resort", "lat": 53.8427845, "lng": -9.3066086},
      {"name": "Knock Parish Church", "lat": 53.7921197, "lng": -8.9193399}
    ]

    venues.forEach(function(ven){
      let infowindow = new window.google.maps.InfoWindow({
        content: "<div class=\"map-venue\">" +
          "<label>" +
            ven["name"] +
          "</label>" +
        "</div>"
      });

      let marker = new window.google.maps.Marker({
        map: map,
        position: {lat: ven["lat"], lng: ven["lng"]},
        title: ven["name"]
      });

      marker.addListener('click', function() {
        console.log(infowindow);
        infowindow.open(map, marker);
      });
    });
  }

  componentDidMount() {
    window.initMap = this.initMap;
    const script = document.createElement('script');
    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDr1pFvLYldXG0P5vJ2PrrnNhM2anlbYW4&callback=initMap";
    script.async = true;
    document.body.appendChild(script);
  }

  render() {
    return <div>
      <div id="events">
        <div className="row">
          <div className="col">
            <h3>Details</h3>
            <p>More details on the below events can be found <Link onClick={() => this.onLinkSelected("wedding-weekend")} to="/wedding-weekend">here</Link>.</p>
            <div className="row">
              <div className="col">
                <h5>Friday 21st February</h5>
                <ul>
                  <li>19:30 - welcome drinks at Breaffy House</li>
                </ul>
              </div>
              <div className="col">
                <h5>Saturday 22nd February</h5>
                <ul>
                  <li>13:00 - ceremony at Knock Parish Church</li>
                  <li>Followed by drinks and canap&eacute;s served on arrival at Breaffy House</li>
                  <li>17:30 - dinner and paarrrrttttaaaayyyy</li>
                </ul>
              </div>
              <div className="col">
                <h5>Sunday 23rd February</h5>
                <ul>
                  <li>17:30 to midnight - party at Village Inn pub in Aghamore</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr/>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-sm-6 col-md-3">
            <h4 style={{textDecoration: "underline"}}>Ceremony</h4>
            <p><label className="venue-name">Knock Parish Church</label><br/>
              Knock Shrine,<br/>
              Knock,<br/>
              County Mayo<br/>
              Ireland<br/>
              <a href="https://www.knockshrine.ie/" target="_blank" rel="noopener noreferrer">Website</a><br/>
              <a href="https://goo.gl/maps/UZVYBmzDuBq" target="_blank" rel="noopener noreferrer">Map</a>
            </p>
          </div>
          <div className="col-sm-6 col-md-3">
            <h4 style={{textDecoration: "underline"}}>Reception</h4>
            <p><label className="venue-name">Breaffy House Resort</label><br/>
              Castlebar,<br/>
              Country Mayo<br/>
              Ireland<br/>
              <a href="https://www.breaffyhouseresort.com" target="_blank" rel="noopener noreferrer">Website</a><br/>
              <a href="https://goo.gl/maps/UzjrqDyHZkw" target="_blank" rel="noopener noreferrer">Map</a>
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div id="accommodation">
        <h3>Accommodation</h3>
        <h4>How to Book?</h4>
        <p>When you book, make sure your stay in <b>Breaffy House Hotel</b> and not Breaffy Woods Hotel. The Woods hotel is on the same site, but not in the main building as the reception.</p>
        <ul>
          <li>Through the Breaffy House Resort <a href="https://www.breaffyhouseresort.com/" target="_blank" rel="noopener noreferrer">website</a></li>
          <li>By calling the hotel directly on: +353 94 9022033</li>
          <li>Possibly on other hotel booking websites (expedia.com for example) nearer the time</li>
        </ul>
      </div>
      <hr />
    </div>
  }
}

export default Wedding;
